/* ===============================================================

 ■ユーザーエージェントの取得

 ・モバイル判定
 Common.user_agent.Mobile
 または
 Common.is_mobile

 ・ブラウザ判定
 Common.user_agent.IE
 Common.user_agent.EDGE
 Common.user_agent.Firefox

 または
 Common.is_ie
 Common.is_firefox



 =============================================================== */

;(function($,window,undefined) {

  var Common;

  Common = Common || {

      DEBUG: true,
      Page: null,
      BREAK_POINT_TAB: 900,
      BREAK_POINT_SP: 767,
      Window: {
        $window: null,
        $document: null,
        width: 0,
        height: 0,
        load: false
      },
      LAYOUT_MODE: "pc",
      current_layout_mode: "",
      user_agent: null,
      is_mobile: false,
      is_android: false,
      is_windows: false,
      is_mac: false,
      is_ie: false,
      is_ie6: false,
      is_ie7: false,
      is_ie8: false,
      is_ie9: false,
      is_ie10: false,
      is_ie11: false,
      is_chrome: false,
      is_safari: false,
      is_opera: false,
      is_firefox: false,
      is_edge: false,
      loaded_img_len: 0,
      img_len: 0,
      img_src_ary: [],
      is_intro_play: true, // イントロが用意されているか？
      is_intro_skip: false, // イントロをスキップするか？
      $body: null,
      timeout_timer: null, // 画像プリロードのタイムアウト用タイマー
      timeout_limit: 3000, // タイムアウトの時間

      wheel_accel: 3, // ホイール強度
      wheel_accel_content: 3, // ホイール強度

      /* -----------------------------------------------
       * 初回に一度だけ実行する
       * ----------------------------------------------- */
      setupOnce: function(){
        Common.trace("func", "setupOnce");

        Common.$body = $("body");

        // ウィンドウ関連の情報をセット
        Common.Window.$window = $(window);
        Common.Window.$document = $(document);
        Common.Window.width = Common.Window.$window.width();
        Common.Window.height = Common.Window.$window.height();

        window.onload = function(){
          Common.Window.load = true;
          window.onload = null;
        };

        // Win/Mac別にホイールの危機具合を調整
        if(this.is_windows){
          this.wheel_accel = 60;
          Common.wheel_accel_content = 30;
        }
        else{
          this.wheel_accel = 30;
          Common.wheel_accel_content = 15;
        }

        var $content_box_wrap = $(".content__box__wrap");

        $(".global-btn").on("click", function(){
          var $this = $(this);
          var $global_wrap = $(".global-wrap");
          if($this.hasClass("is-open")){
            $this.removeClass("is-open");
            $global_wrap.removeClass("is-open");
            Common.enableScroll();
          }
          else{
            $this.addClass("is-open");
            $global_wrap.addClass("is-open");
            Common.disableScroll();
          };
        });
        $(".splash__moreBtn").on("click", function(){
          $content_box_wrap.addClass("is-open");
          // Common.disableScroll();
        });
        $(".content-close").on("click", function(){
          $content_box_wrap.removeClass("is-open");
          // Common.enableScroll();
        });

        Common.PageEvent.init();

        // ユーザーエージェント情報を取得、bodyにもクラス名として付与する
        Common.user_agent = Common.setUserAgent();

        // PC/SP のレイアウトモード判定
        if(Common.Window.width <= Common.BREAK_POINT_SP){
          Common.LAYOUT_MODE = "sp";
        }
        else{
          if(Common.user_agent.Mobile){
            Common.LAYOUT_MODE = "sp";
          }else{
            Common.LAYOUT_MODE = "pc";
          };
        };

        Common.current_layout_mode = Common.LAYOUT_MODE;
        Common.ResizeEvent.init();
        // Common.setHoverEvent(); // スマホのホバー制御

        $("#blind .blind__loader").velocity("stop").velocity({opacity: 1},{ delay: 600, duration: 400 });
        Common.initPreLoader();

      },

      disableScroll: function(){
        // スクロール禁止
        Common.Window.$window.on('touchmove.noScroll', function(e) {
          e.preventDefault();
          return false;
        });
        Common.Window.$window.on('mousewheel', function(e, delta, deltaX, deltaY) {
          e.preventDefault();
          return false;
        });
      },

      enableScroll: function(){
        // スクロール禁止 解除
        Common.Window.$window.off('.noScroll');
        Common.Window.$window.off('mousewheel');
      },

      /* -----------------------------------------------
       * リサイズイベント
       * ----------------------------------------------- */
      ResizeEvent: {
        vsl_ratio: 1280 / 720,
        window_ratio: 0,
        bg_offset: 0,
        init: function(){
          // console.log("ResizeEvent -> init()");

          // console.log(Common.Window.$window);
          // Common.Window.$window.off("resize");
          Common.Window.$window.on("resize", Common.ResizeEvent.onResize).trigger("resize");

        },
        resizeTimer: null,
        onResize: function(e){
          Common.trace("Common -> ResizeEvent -> onResize()");

          Common.Window.width = Common.Window.$window.width();
          Common.Window.height = Common.Window.$window.height();

          if( Common.LAYOUT_MODE != Common.current_layout_mode ){
            location.reload();
            return;
          };

        }
      },



      /* -----------------------------------------------
       * ページ遷移関連の処理
       * ----------------------------------------------- */
      PageEvent: {
        init: function(){
          Common.trace("PageEvent -> init()");

          var direction;
          var position;
          var scroll_top;
          var $scrollable = $(".scrollable");

          if( $(".page-gallery").length <= 0 ){
            $scrollable.on('touchmove.noScroll', function(e) {
              e.preventDefault();
              return false;
            });
          }
          else{
            $scrollable = $(".gallery__bg");
          }

          $scrollable.on({
            'touchstart': onTouchStart, //指が触れたか検知
            'touchmove': onTouchMove, //指が動いたか検知
            'touchend': onTouchEnd //指が離れたか検知
          });
          Common.Window.$window.on("keydown", function(e){

            // console.log(scroll_top)
            // console.log(e.keyCode)

            if( $(".page-gallery").length > 0 ){
              scroll_top = document.body.scrollTop || document.documentElement.scrollTop;
              if( scroll_top <= 0 && e.keyCode == 38 ){
                Common.PageEvent.scrollUp();
              }
              return;
            }

            if(e.keyCode == 40){
              Common.PageEvent.scrollDown();
            }
            if(e.keyCode == 38){
              Common.PageEvent.scrollUp();
            };

          });

          function onTouchStart(event){
            scroll_top = document.body.scrollTop || document.documentElement.scrollTop;
            position = getPosition(event);
            direction = ''; //一度リセットする
          };
          function onTouchMove(event){
            // e.preventDefault();
            if (position - getPosition(event) > 40) { // 40px以上移動しなければスワイプと判断しない
              direction = 'up'; //左と検知
            } else if (position - getPosition(event) < -40){  // 40px以上移動しなければスワイプと判断しない
              direction = 'down'; //右と検知
            };
          };
          function onTouchEnd(event){

            if( $(".page-gallery").length > 0 ){
              if( scroll_top <= 0 && direction == 'down' ){
                Common.PageEvent.scrollUp();
              }
              return;
            }

            if (direction == 'up'){
              Common.PageEvent.scrollDown();
            } else if (direction == 'down'){
              Common.PageEvent.scrollUp();
            };

          };

          //横方向の座標を取得
          function getPosition(event) {
            return event.originalEvent.touches[0].pageY;
          };

        },
        scrollDown: function(){
          Common.trace("scrollDown");

          var _url = Common.$body.attr("data-next-url");
          if(_url == "") return;

          pageFadeOut( _url );

        },
        scrollUp: function(){
          Common.trace("scrollUp");

          var _url = Common.$body.attr("data-prev-url");
          if(_url == "") return;

          pageFadeOut( _url );

        },

      },

      initPreLoader: function(){
        Common.trace("Common -> initPreLoader()");

        this.img_len = $('img').length;

        Common.timeout_timer = setTimeout(function(){
          Common.hidePreLoader();
        }, Common.timeout_limit);

        if(this.img_len > 0) this.setPreLoader();
        else this.hidePreLoader();
      },

      setPreLoader: function(){
        var _this = this;

        Common.trace('PRELOADER', 'initLoad');

        // IE8の場合はすぐにトップ表示に移る
        // if(Common.$body.hasClass("ie8")){
        // 	hidePreLoader();
        // 	return;
        // };

        for(var i = 0; i < this.img_len; i++){
          var _src = $('img:eq(' + i + ')').attr('src');
          _this.img_src_ary.push(_src);
          // Common.trace(_src);
        };

        $(document).smartpreload({
          images: _this.img_src_ary,
          oneachimageload: function(src) {
            _this.loaded_img_len++;
            Common.onLoadUpdate();
          },
          onloadall: function() {
            Common.trace('PRELOADER :: All item is loaded.');
            Common.hidePreLoader();
          }
        });
      },

      onLoadUpdate: function(){
        var _this = this;

        Common.trace('PRELOADER :: ' + _this.loaded_img_len + '/' + _this.img_src_ary.length + ' Loaded');
        // Common.trace('PRELOADER :: ' + _per + '% Loaded');

        if (_this.loaded_img_len == _this.img_src_ary.length){
          Common.trace('PRELOADER :: All item is loaded.');
        };
      },


      /* -----------------------------------------------
       * PRE LOADER ANIMATION
       * ----------------------------------------------- */

      showPreLoader: function(){
        Common.trace('Common -> showPreloader');
        Common.initPreLoader();
      },

      hidePreLoader: function(){
        Common.trace('Common -> hidePreLoader');
        this.init();
      },
      /* -----------------------------------------------
       * 初期化
       * ----------------------------------------------- */
      reload_count: 0,
      reload_count_max: 2,
      init: function(){
        Common.trace("Common -> init()");

        clearTimeout(Common.timeout_timer);
        Common.timeout_timer = null;

        if(!Common.Page && this.reload_count < this.reload_count_max){
          this.reload_count++;
          setTimeout(Common.init, 500);
          return;
        };

        Common.trace(Common.Page);
        if(Common.Page) {
          setTimeout(function(){
            Common.Page.setupOnce();
          }, 30);
        }

        pageFadeIn();

      },

      /* -----------------------------------------------
       * ユーザーエージェントの取得
       * bodyタグにエージェント名をクラスとして付与する
       * ----------------------------------------------- */
      setUserAgent: function(){
        Common.trace("Common -> setUserAgent()");

        var ua = window.navigator.userAgent.toLowerCase();
        var ver = window.navigator.appVersion.toLowerCase();
        var name = 'unknown';

        if( window.navigator.platform.indexOf("Win") != -1 ){
          Common.is_windows = true;
        }
        else if(ua.match(/Mac|PPC/)){
          Common.is_mac = true;
        };

        if (ua.indexOf("msie") != -1){
          if (ver.indexOf("msie 6.") != -1){
            name = 'ie6';
            Common.is_ie6 = true;
          }else if (ver.indexOf("msie 7.") != -1){
            name = 'ie7';
            Common.is_ie7 = true;
          }else if (ver.indexOf("msie 8.") != -1){
            name = 'ie8';
            Common.is_ie8 = true;
          }else if (ver.indexOf("msie 9.") != -1){
            name = 'ie9';
            Common.is_ie9 = true;
          }else if (ver.indexOf("msie 10.") != -1){
            name = 'ie10';
            Common.is_ie10 = true;
          }else{
            name = 'ie';
            Common.is_ie = true;
          }
        }else if(ua.indexOf('trident/7') != -1){
          name = 'ie11';
          Common.is_ie11 = true;
        }else if (ua.indexOf('chrome') != -1){
          name = 'chrome';
          Common.is_chrome = true;
        }else if (ua.indexOf('safari') != -1){
          name = 'safari';
          Common.is_safari = true;
        }else if (ua.indexOf('opera') != -1){
          name = 'opera';
          Common.is_opera = true;
        }else if (ua.indexOf('firefox') != -1){
          name = 'firefox';
          Common.is_firefox = true;
        };
        if (ua.indexOf('edge') != -1){
          name = 'edge';
          Common.is_edge = true;
        };

        Common.$body.addClass(name);
        if(ua.indexOf("mobile") != -1){
          Common.$body.addClass("mobile");
          Common.is_mobile = true;
        };
        if(ua.indexOf("android") != -1){
          Common.$body.addClass("android");
          Common.is_android = true;
        };


        return (function(u){

          var _is_ie = (u.indexOf('trident/7') > -1) || (u.indexOf('msie') > -1) && (u.indexOf('opera') == -1);

          return {
            Tablet:(u.indexOf("windows") != -1 && u.indexOf("touch") != -1)
            || u.indexOf("ipad") != -1
            || (u.indexOf("android") != -1 && u.indexOf("mobile") == -1)
            || (u.indexOf("firefox") != -1 && u.indexOf("tablet") != -1)
            || u.indexOf("kindle") != -1
            || u.indexOf("silk") != -1
            || u.indexOf("playbook") != -1,
            Mobile:(u.indexOf("windows") != -1 && u.indexOf("phone") != -1)
            || u.indexOf("iphone") != -1
            || u.indexOf("ipod") != -1
            || (u.indexOf("android") != -1 && u.indexOf("mobile") != -1)
            || (u.indexOf("firefox") != -1 && u.indexOf("mobile") != -1)
            || u.indexOf("blackberry") != -1,
            Android: (u.indexOf("android") != -1),
            iOS:(u.indexOf("iphone") != -1)
            || u.indexOf("ipod") != -1
            || u.indexOf("ipad") != -1,
            IE: _is_ie,
            IE_VERSION: ( !_is_ie )? -1 : parseInt( u.match(/((msie|MSIE)\s|rv:)([\d\.]+)/)[3] ),
            Chrome: (u.indexOf('chrome') > -1) && (u.indexOf('edge') == -1),
            Firefox: (u.indexOf('firefox') > -1),
            Safari: (u.indexOf('safari') > -1) && (u.indexOf('chrome') == -1),
            Opera: (u.indexOf('opera') > -1),
            Edge: (u.indexOf('edge') > -1)
          }
        })(ua);

      },

      trace: function( args ){
        if(!Common.DEBUG) return;
        // if (jQuery.browser.msie) {return};
        void 0;
      }

    };


  // 戻るボタンで起動しない問題の対応処理
  window.onpageshow = function(event) {
    Common.trace("戻るボタンによる再読込: 判定")
    if (event.persisted) {
      Common.trace("戻るボタンによる再読込: リロード")
      window.location.reload();
      return;
    };
  };

  Common.setupOnce();
  window.Common = Common;




  /* -----------------------------------------------
   * ページ フェード・アウト
   * ----------------------------------------------- */
  var is_anim = true;
  var $blind = $('#blind');

  $("a[href!='#']").on('click', function(e){

  	if($(this).hasClass("no-fade")){
  		e.preventDefault();
  		return;
  	};

  	var $this = $(this);

  	if($this.attr('target') == "_blank"){
  		return;
  	};

  	e.preventDefault();

    pageFadeOut( $this.attr('href') );

  });

  function pageFadeOut(opt_href){

    $(".scrollable").off();
    Common.Window.$window.off();

    TweenMax.killAll();
    TweenMax.fromTo($blind, .3, { display: "block" }, { opacity: 1, delay: 0, ease: Power3.easeOut,
      onComplete: function(){
        window.location = opt_href;
        // target = "_self";
      }
    })

  };

  /* -----------------------------------------------
   * ページ フェード・イン
   * ----------------------------------------------- */
  function pageFadeIn(){
    Common.trace("pageFadeIn()");

    if($(".intro").length) return;

    TweenMax.fromTo($blind, 2, { opacity: 1 }, { opacity: 0, delay: 0, ease: Power3.easeOut,
    onComplete: function(){
      $blind.hide();
    }
    })
  }

})(jQuery,window);

